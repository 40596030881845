// API
import BaseAPI from './base';

/**
 * Implement Office API
 */
export default class OfficeAPI extends BaseAPI {
  constructor() {
    super('offices');
  }

  /**
   * Get available offices within a postal code area.
   */
  availableOffices(data) {
    return this.request.post(`${this.baseURI}/available-offices`, data);
  }

  /**
   * Save office's logo.
   */
  logo(id, data) {
    return this.request.post(`${this.baseURI}/${id}/logo`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  /**
   * Get office's products.
   */
  products(id, params) {
    return this.request.get(`${this.baseURI}/${id}/products`, { params });
  }

  /**
   * Verify if name is available.
   */
  verifyName(data) {
    return this.request.post(`${this.baseURI}/verify-name`, data);
  }
}
