export default {
  data: () => ({
    dialog: false,
    items: [],
    loading: false,
    options: {
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
    },
    search: '',
    selectedItem: {},
    timeout: null,
    total: 0,
  }),

  watch: {
    options: {
      async handler() {
        await this.list();
      },
      deep: true,
    },

    search() {
      this.debounce(this.list);
    },
  },

  methods: {
    debounce(callback, time = 500) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(callback, time);
    },

    async list() {
      this.loading = true;
      try {
        const { count, results } = await this.api.list(this.getQuery());
        this.items = results;
        this.total = count;
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.loading = false;
    },

    getQuery() {
      const {
        sortBy,
        sortDesc,
        itemsPerPage,
      } = this.options;
      let {
        page,
      } = this.options;
      let orderSymbol = '';

      if (sortBy.length && sortDesc[0]) {
        orderSymbol = '-';
      }

      if (this.search) {
        page = 1;
      }

      return {
        ordering: orderSymbol + sortBy,
        page_size: itemsPerPage,
        page,
        search: this.search,
      };
    },

    handleDelete(item) {
      this.selectedItem = item;
      this.dialog = true;
    },

    async remove() {
      this.$store.commit('showLoader');
      try {
        await this.api.delete(this.selectedItem.uuid);
        await this.list();
        this.dialog = false;
        this.$store.dispatch('notification/notifySuccess', 'Eliminado exitósamente.');
      } catch (error) {
        this.$store.dispatch('notification/notifyError', 'Ocurrió un error al eliminar.');
      }
      this.$store.commit('hideLoader');
    },
  },
};
